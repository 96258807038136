import React, { useState } from "react";
import SubHeader from "../components/SubHeader.jsx";
import {
  Row,
  Container,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import PractitionersList from "../app/shared/features/practitioners/PractitionersList";
import { PRACTITIONERS } from "../app/shared/features/practitioners/PRACTITIONERS.js";

const PractitionerCarousel = ({ practitioners }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === practitioners.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? practitioners.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <Container
    >
      <Row>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
        >
          <CarouselIndicators
            items={PRACTITIONERS}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {PRACTITIONERS.map((practitioner, index) => (
            <CarouselItem key={index}>
              {/* Render individual practitioner details here */}
              <img
                src={practitioner.image}
                alt={practitioner.name}
                className="people-carousel-img"
              />
              <CarouselCaption
                captionText={practitioner.about}
                captionHeader={practitioner.name}
              />
            </CarouselItem>
          ))}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
      </Row>
    </Container>
  );
};

const PractitionerPage = () => {
  const initialPractitioners = PRACTITIONERS.slice(0, 6); // Adjust the range as needed

  return (
    <div>
      <PractitionerCarousel practitioners={initialPractitioners} />
      <PractitionersList practitioners={initialPractitioners} />
    </div>
  );
};

export default PractitionerPage;
