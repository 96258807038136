import MapComponent from "../components/Map";
import {
  Container,
  Row,
  Col
} from "reactstrap";

const ContactPage = () => {
  return (
    <Container className="d-xl-inline">
      <Row className="intro-text">
        <h2 className="mb-4">Book an Appointment Today</h2>
        {/* <Col className="col col-12 col-md-6">
          <p className="specials-text">
            New Client Special: Receive $10.00 off your first session with us!
          </p>
        </Col> */}
        <Col>
          <p>
            We’re conveniently located in the heart of Village Park in beautiful
            Encinitas, providing integrative healthcare services to the
            surrounding communities of Encinitas, Cardiff by the Sea, Leucadia,
            Carlsbad, Solana Beach, Rancho Santa Fe, Del Mar and all of North
            San Diego County since 1998.
          </p>
        </Col>
      </Row>
      <Row className="contact-page-contact-info">
        <Row className="mt-4 mb-4">
          <Col xs="12" className="text-sm-center">
            <a href="tel:7604158620">
              <i className="fa fa-phone"></i> 7604158620
            </a>
          </Col>
          {/* <Col xs="12" sm="6" className="text-sm-center">
            <a href="mailto:info@bodytherapyarts.com">
              <i className="fa fa-envelope"></i> info@bodytherapyarts.com
            </a>
          </Col> */}
        </Row>
      </Row>
      <Row>
        <Col></Col>
        <Col className="map-component col-12 col-xl-10">
          <MapComponent />
        </Col>
        <Col></Col>
        <Col xs="12" className="text-sm-center mb-4 contact-page-contact-info">
          <a
            href="https://www.google.com/maps/dir//1991+Village+Park+Way,+Encinitas,+CA+92024/@33.053135,-117.3280127,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x80dc0b95bd8b1c0f:0xd2d309cdd42fa7b!2m2!1d-117.2456115!2d33.0531621?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-map" /> 1991 Village Park Way, Encinitas, CA
            92024
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactPage;
