import SubHeader from "../components/SubHeader.jsx";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardImgOverlay,
} from "reactstrap";
import { Link } from "react-router-dom";
import Space from "../app/assets/img/mindful.png";
import Services from "../app/assets/img/massage.jpg";
import { NavLink } from "react-router-dom";

const HomePage = () => {
  return (
    <>
      <Row className="intro-text">
        <Col>
          <h2 className="intro-header-text">
            Body Therapy Arts has been offering a range of holistic services to
            Encinitas, CA for over 30 years.
          </h2>
          <NavLink className="btns contact-info-btn get-in-touch-btn" to="/contact">
            <span>Get in touch</span>
          </NavLink>
          <h3>
            We are a group of independent healthcare practitioners offering
            massage therapy, acupuncture, naturopathic medicine, hypnotherapy,
            and personalized skincare. Specializing in pregnancy massage, deep
            tissue massage, acupuncture for women's health and fertility,
            acupuncture for musculoskeletal pain, as well as support for weight
            loss through hypnotherapy and natural medicine.
          </h3>
        </Col>
      </Row>
      <Row className="homepage-card-container">
        <Col className="col-12 col-lg-6">
          <Link to="/suite">
            <Card className="homepage-card">
              <CardImgOverlay className="card-img-overlay">
                <CardTitle className="card-title">The Space</CardTitle>
              </CardImgOverlay>
              <CardBody>
                <img
                  src={Space}
                  className="homepage-img img-fluid"
                  alt="picture of the space"
                ></img>
              </CardBody>
            </Card>
          </Link>
        </Col>
        <Col className="col-12 col-lg-6">
          <Link to="/services">
            <Card className="homepage-card">
              <CardImgOverlay className="card-img-overlayr">
                <CardTitle className="card-title">Services</CardTitle>
              </CardImgOverlay>
              <CardBody>
                <img
                  src={Services}
                  className="homepage-img img-fluid"
                  alt="services"
                ></img>
              </CardBody>
            </Card>
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default HomePage;
